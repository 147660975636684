import React from 'react';
import PropTypes from "prop-types";

import Img from "gatsby-image"



const FullScreenHero = ({ data }) => {

      return (

            <>
                  <div className="c-fullscreen-hero">

                        <div className="c-fullscreen-hero__top o-align-middle js-header-waypoint" data-logo="light" data-nav="light">
                              <div className="c-fullscreen-hero__aligner o-align-middle__aligner">
                                    <div className="o-wrapper">
                                          <span className="c-fullscreen-hero__kicker c-heading-group__kicker">Blog</span>
                                          <h2 className="c-fullscreen-hero__title c-heading-group__title">{data.title[0].text}</h2>
                                    </div>
                              </div>
                              {data.image && 

                                    <Img className="c-fullscreen-hero__image u-img" fixed={data.imageSharp.childImageSharp.fluid} />
                              
                              }
                        </div>
                        <div className="c-fullscreen-hero__waypoint js-header-waypoint" data-logo="dark" data-nav="dark" />

                        {data.description &&
                              <div className="o-wrapper">
                                    <div className="c-fullscreen-hero__box o-align-middle">
                                          <div className="o-align-middle__aligner">
                                                <p className="c-fullscreen-hero__description u-serif">{data.description[0].text}</p>
                                          </div>
                                    </div>
                              </div>
                        }
                  </div>
            </>


      );
}

FullScreenHero.propTypes = {
      data: PropTypes.object
}

export default FullScreenHero;