import React from 'react';
import PropTypes from "prop-types";
import RichText from '../richText';



const Content = ({ data }) => {

      return (

            <>
                  <div className="c-blog-post__content u-content u-serif u-justify">

                        <RichText render={data} />

                  </div>
            </>


      );
}

Content.propTypes = {
      data: PropTypes.array
}

export default Content;